import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
// import logo from '../../assets/img/mh-logo.png';
import axios from 'axios';
import config from '../../config';
import authImg from '../../assets/images/auth.png'
import eyeInvisible from "../../assets/svgs/eyeInvisible.svg"
import eyeVisible from "../../assets/svgs/eyeVisible.svg"
import studentSVG from "../../assets/svgs/studentReg.svg"
import corporateSVG from "../../assets/svgs/corporateReg.svg"
import medicalSVG from "../../assets/svgs/medicalCenterReg.svg"
import trainingSVG from "../../assets/svgs/trainingCenterReg.svg"
import { useTranslation } from 'react-i18next';

function Login() {
  const { t, i18n } = useTranslation();
  const [locale, setLocale] = useState("en")
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginErrpor] = useState(true);

  const [showErrors, setShowErrors] = useState(false)
  const navigate = useNavigate();
  const location = useLocation();
  if(location.pathname.length < 2){
    location.pathname = "/login"
  }

  async function login() {
    localStorage.removeItem("user");
    localStorage.removeItem("userId");
    localStorage.removeItem("privileges");
    localStorage.removeItem("type");
    localStorage.removeItem("studentId");

    // navigate('/admin/dashboard');
    // test@test.com
    // password123

    //   localStorage.setItem("user", 'sdsdcskljdnfadlnkcadca')
    //   navigate('/admin/dashboard');
    //   return
     await axios.post(config.API_URL + '/auth/login', { email: email, password: password }).then((res) => {
      // console.log('res? ',res.data)
      // alert('wrokgin')
      // console.log('res.status ',res.status)
      if (res.data.status === 401) {
        setShowErrors(true);
      }
      if (res.data.access_token) {
        localStorage.setItem("user", JSON.stringify(res.data.access_token));
        localStorage.setItem("userId", JSON.stringify(res.data?.userId));
        localStorage.setItem("type", res.data?.type);
        localStorage.setItem("studentId", res.data?.studentId);
        // localStorage.setItem("privileges", JSON.stringify(res.data?.privileges));
        // navigate('/admin/dashboard');
        setTimeout(() => {
          //'Amanah','Teller','Instructor','super admin','manager','student','corporate','branch admin'
          if(res.data?.type === 'student'){
            navigate('/student/dashboard');
            return
          }
          if(res.data?.type === 'corporate') {
            navigate('/corporate/dashboard');
            return
          }
          if (res.data?.type === 'Instructor' ) {
            navigate('/instructor/dashboard');
            return
          }
          if(res.data?.type === 'Examiner'){
            navigate('/examiner/dashboard');
            return
          }else {
            navigate('/admin/dashboard');

          }
        }, 1000);
      }
    }).catch((error) => {
      console.log('error? ', error)
      setShowErrors(true);
    })

    // if (email === 'test@test.com') {
    //   navigate('/admin/dashboard');
    // } else {
    //   alert('Wrong Information!');
    // }
  }

  return (
    <>
    <div className='fixed top-0 left-0 w-full z-[99] sm:bg-[#fff] bg-primary'>
      <div className='flex items-center justify-between w-[97%] mx-auto py-4 flex-row'>
        <img className='w-80' src='/assets/images/mh-logo.png' alt="logo" />
      </div>
    </div>
      <div className="w-full pb-20 h-full mt-20">
        <div className="lg:w-[80%] w-[95%] h-full mx-auto flex justify-center gap-8 pt-20">
          <div className="flex-1 md:block hidden">
            <img src={authImg} alt="Auth" className="w-[80%]" />
          </div>
          <div className="flex-1">
            <div
              className={`flex items-center gap-12
                  ${locale === 'en' ? 'justify-start' : 'justify-end'}
                  `}
            >
              {['login', 'register','products'].map((item, i) => (
                item !== 'products' ?
                <div
                  onClick={() => { navigate(`/${item}`) }}
                  className={`font-semibold text-xl cursor-pointer
            ${location.pathname.includes(item)
                      ? 'text-primary relative after:content-[""] after:absolute after:-bottom-3 after:left-0 after:w-full after:h-[5px] after:bg-[#046f6d]'
                      : ''
                    } `}
                  key={i}
                >
                  {item === 'login' ? t('Login') : t('Register')}
                </div>
                :
                <div
                onClick={() => { navigate(`/products`) }}
                className={`font-semibold text-xl cursor-pointer`}
                key={i}
              >
                {t('Products')}
              </div>
              ))}
            </div>
            {location.pathname.includes("login") ?
              <div className='mt-12'>
                <form className="flex flex-col gap-4">
                  <div className="flex flex-col gap-2">
                    <label className={`${i18n.language === "en" || locale === 'en'? '' : ''}`}>
                      {t('Email / ID')}
                    </label>
                    <input
                      type="text"
                      placeholder="mail@example.com"
                      className={`py-2 px-3 rounded-md text-dark focus:border-primary border outline-none text-left
                  ${locale === 'en' ? 'text-left' : 'text-right'}
                  `}
                      value={email}
                      onChange={(event) =>
                        setEmail(event.target.value)
                      }
                    />
                  </div>
                  <div className="flex flex-col gap-2 relative">
                  <label className={`${i18n.language === "en" || locale === 'en'? '' : ''}`}>
                      {t('Password')}
                    </label>
                    <input
                      type={`${showPassword ? 'text' : 'password'}`}
                      placeholder="**********"
                      className={`py-2 px-3 rounded-md text-dark focus:border-primary border outline-none text-left
                  ${locale === 'en' ? 'text-left' : 'text-right'}
                  `}
                      value={password}
                      onChange={(event) => {
                        setPassword(event.target.value)
                      }
                      }
                    />
                    <div
                      className={`absolute ${locale === 'en' ? 'right-2' : 'left-2'
                        } top-[55%] cursor-pointer w-fit`}
                    >
                      {showPassword ? (
                        <img
                          src={eyeInvisible}
                          alt="Eye"
                          className="w-[24px] "
                          onClick={() => setShowPassword(false)}
                        />
                      ) : (
                        <img
                          src={eyeVisible}
                          alt="Eye"
                          className="w-[24px]"
                          onClick={() => setShowPassword(true)}
                        />
                      )}
                    </div>
                  </div>
                  {showErrors && loginError ?
                      <span className='text-red-400'>{t('Invalid email or password')}</span>
                      :
                      <></>
                    }
                  {/* <div className="flex items-cente justify-between mt-4">
                    <div className="flex items-center gap-2 text-sm">
                      <input type="checkbox" id="rememberMe" />
                      <label htmlFor="rememberMe">{t('Remember Me')}</label>
                    </div>
                    <div className="text-grayText text-sm cursor-pointer">
                      {t('Forget Password')}
                    </div>
                  </div> */}
                  <div className="mt-6">
                    <button
                      className="py-2 px-6 bg-[#046f6d] text-[#fff] rounded-tl-[22px] rounded-br-[22px] rounded-bl-[7px] rounded-tr-[7px] hover:bg-primaryHover w-full"
                      onClick={(event) => {
                        event.preventDefault();
                        login();
                      }}
                    >
                      {t('Login')}
                    </button>
                  </div>
                </form>
              </div>
              :
              <div
                className={`flex flex-col gap-4 w-full mt-12
    ${locale === 'en' ? 'justify-start' : 'justify-end'}
    `}
              >
                <p
                  className={`text-grayText w-full
        ${locale === 'en' ? '' : ''}
        `}
                >
                  {t('Select one of these option to determine how will you register')}
                </p>
                <Link to='/student-register'>
                  <div
                    className={`flex items-center gap-4 mt-4
        ${locale === 'en' ? 'flex-row' : 'flex-row-reverse'}
        `}
                    onClick={() => { }}
                  >
                    <div className="w-[60px] h-[60px] flex items-center justify-center bg-lightBlue rounded-full">
                      <img src={studentSVG} alt="SVG" />
                    </div>
                    <p className="font-semibold">{t('I Am Student')}</p>
                  </div>
                </Link>
                <Link to='/corporate-register'>
                  <div
                    className={`flex items-center gap-4 mt-4
        ${locale === 'en' ? 'flex-row' : 'flex-row-reverse'}
        `}
                  >
                    <div className="w-[60px] h-[60px] flex items-center justify-center bg-lightBlue rounded-full">
                      <img src={corporateSVG} alt="SVG" />
                    </div>
                    <p className="font-semibold">{t('Corporate')}</p>
                  </div>
                </Link>
                <Link to='/training-register'>
                <div
                  className={`flex items-center gap-4 mt-4
        ${locale === 'en' ? 'flex-row' : 'flex-row-reverse'}
        `}
                >
                  <div className="w-[60px] h-[60px] flex items-center justify-center bg-lightBlue rounded-full">
                    <img src={trainingSVG} alt="SVG" />
                  </div>
                  <p className="font-semibold">{t('Training Center')}</p>
                </div>
                </Link>
                <Link to='/medical-register'>
                <div
                  className={`flex items-center gap-4 mt-4
        ${locale === 'en' ? 'flex-row' : 'flex-row-reverse'}
        `}
                >
                  <div className="w-[60px] h-[60px] flex items-center justify-center bg-lightBlue rounded-full">
                    <img src={medicalSVG} alt="SVG" />
                  </div>
                  <p className="font-semibold">{t('Medical Center')}</p>
                </div>
                </Link>
              </div>
            }
          </div>
        </div>
    </div>
    </>
  );
}

export default Login;
